import React from "react";
import moment from "moment";

const ConfigureModal = ({
  state,
  handleWindowCloser,
  windowCloserSubmit,
  windowCloserSubmitShare,
  getDate,
  company,
  windowClouserTestMail,
}) => {
  return (
    <div id="configure-modal" class="modal">
      <div class="row modal-content">
        <div className="row modal-title ">
          <span>Trading Window Closure</span>
        </div>
        <div>
          <form style={{ margin: 10 }}>
            <div>
              <label for="from">Closure Start Date:</label>
              <input
                type="date"
                id="from"
                name="from"
                min={state.today}
                onChange={handleWindowCloser}
              />
            </div>
            <div>
              <label for="to">Window Closure Ending Date:</label>
              <input
                type="date"
                id="to"
                name="to"
                min={state.today}
                onChange={handleWindowCloser}
              />
            </div>
          </form>
          <div className="row">
            <p>
              <label>Purpose</label>
            </p>
            <div className="col s12 m12 l12">
              <textarea
                onChange={handleWindowCloser}
                id="purpose"
                rows="2"
                cols="80"
                name="purpose"
                form="usrform"
                style={{ resize: "none", padding: 10 }}
              ></textarea>
            </div>
            <div className="col s12 m12 l12">
              {company &&
                company.window_close_from &&
                company.window_close_to && (
                  <span style={{ transform: "translate(-5px, 8px)" }}>
                    {" "}
                    {"Configured window closure period: " +
                      getDate(company.window_close_from) +
                      " to " +
                      getDate(company.window_close_to)}
                  </span>
                )}
            </div>
          </div>
          <div className="row">
            <div className="col s12">
              <input
                id="testMail"
                placeholder="Test Mail..."
                onChange={handleWindowCloser}
                value={state.testMail}
              />
            </div>
            <div className="col s12">
              <button
                id="sendTestMail"
                className="btn"
                disabled={!state.testMail}
                onClick={windowClouserTestMail}
              >
                Submit & Send Test Mail
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button className="modal-close waves-effect waves btn-flat">
          Cancel
        </button>
        <button
          className="waves-effect waves modal-close btn-flat"
          onClick={windowCloserSubmitShare}
        >
          submit & send
        </button>
        <button
          className="waves-effect waves modal-close btn-flat"
          onClick={windowCloserSubmit}
        >
          submit
        </button>
      </div>
    </div>
  );
};

export default ConfigureModal;
