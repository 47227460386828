export const refreshInterval = 5 * 60 * 1000; // 2 minutes

export const text = {
  tile: {
    viewInsider: "DP/ Insider Management",
  },
  leftBar: {
    viewInsiders: "VIEW DP/Insiders",
    addInsiders: "ADD DP/Insiders",
    addNewCP: "ADD NEW CP/INSIDER",
  },
  cp: "INSIDER",
};

export const timezone = "Asia/Kolkata";
